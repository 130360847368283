<template>
  <div>
    <h4>About</h4>
  </div>
</template>

<script>
export default {
  name: "About",
  methods: {},
  computed: {},
  mounted() {},
};
</script>
<style lang="scss">
h1 {
  font-size: 48px;
}
</style>
