<template>
  <div>
    <h1>Wizdoms</h1>
    <h2>Gospel according to Bengtsson</h2>
    <ul>
      <li>
        <strong>#1</strong> Never say a course is made for you just because you
        made a good round, once...
      </li>
      <li><strong>#2</strong> Putt above the basket edge</li>
      <li><strong>#3</strong> Putt below the basket band</li>
      <li>
        <strong>#4</strong> Always wear full-length pants with enough stretch
      </li>
      <li><strong>#5</strong> Never weakside!</li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "Wizdoms",
  methods: {},
  computed: {},
  mounted() {},
};
</script>
<style lang="scss" scoped>
h1 {
  font-size: 42px;
  color: #edf5e1;
  font-weight: 700;
  -webkit-text-stroke: 2px #05386b; /* width and color */
  text-shadow: 3px 2px 1px #05386b;
}
ul {
  list-style: none;
  padding: 0;
  li {
    font-size: 16px;
    padding: 16px 0;
    border-bottom: 1px solid white;
  }
}
</style>
