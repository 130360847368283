<template>
  <div class="start container">
    <div class="row">
      <div class="col-12 center">
        <h1>Discgolf Bingo</h1>
      </div>
    </div>
    <div class="row">
      <div class="col-6">
        <h2>Play now, it's free!</h2>
        <p>
          With discgolfnetwork providing excellent live coverage of all major
          Discgolf events more and more people join in on these broadcasts.
        </p>

        <p>
          Discgolf Bingo is the perfect addition to this, compete against some
          friends like we do over at Tjing's Discord where we chat and chase the
          Bingo.
        </p>
      </div>

      <div class="col-6">
        <div class="image">
          <img src="@/assets/images/font-page_1.jpg" alt="" />
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-5">
        <p>
          Signup with your email and get instant access to your bingo card, this
          is a hobby project and no big coorporation trying to get you with in
          app purchases or similar. We would kindly ask you to disable any
          adblcokers to help us keep the site going.
        </p>
      </div>
      <div class="col-6">
        <div class="image">
          <img src="@/assets/images/font-page_2.jpg" alt="" />
        </div>
      </div>
      <div class="col-1"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Start",
  methods: {},
  computed: {},
  mounted() {},
};
</script>

<style lang="scss" scoped>
h1 {
  font-size: 48px;
  color: #edf5e1;
  font-weight: 700;
  text-align: center;
  -webkit-text-stroke: 2px #05386b; /* width and color */
  text-shadow: 3px 2px 1px #05386b;
}
h2 {
  font-size: 36px;
  color: #edf5e1;
  margin: 0;
}
.image {
  border: 1px solid white;
  overflow: hidden;
  border: 3px solid white;
  border-radius: 20px;
  box-shadow: 6px 9px 1px white;
  width: 100%;
  height: 250px;

  img {
    width: 120%;
  }
}

.quote {
  box-sizing: border-box;
  padding: 30px;
  font-size: 32px;
  font-weight: 300;
  border: 3px solid white;
  border-radius: 20px;
  box-shadow: 6px 9px 1px white;

  strong {
    font-weight: 700;
  }
}
/* SMARTPHONES PORTRAIT */
@media only screen and (min-width: 300px) {
}

/* SMARTPHONES LANDSCAPE */
@media only screen and (min-width: 480px) {
}

/* TABLETS PORTRAIT */
@media only screen and (min-width: 768px) {
}

/* TABLET LANDSCAPE / DESKTOP */
@media only screen and (min-width: 1024px) {
  h1 {
    margin: 24px 0 64px 0;
    font-size: 124px;
    color: #edf5e1;
    font-weight: 700;
    text-align: center;
    -webkit-text-stroke: 3px #05386b; /* width and color */
    text-shadow: 6px 9px 1px #05386b;
  }
}
</style>
