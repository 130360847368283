<template>
  <div class="bingo-card">
    <div class="header">
      <h1>{{ data.card.name }}</h1>
    </div>

    <div class="main">
      <div class="grid" @mouseleave="shownDetails = null">
        <div
          class="item"
          @mouseenter="shownDetails = item"
          v-for="item in data.card.values"
          :class="{ checked: item.checked }"
          :key="item.id"
          @click="toggleItemStatus(item)"
        >
          {{ item.title }}
        </div>
      </div>
    </div>
    <div class="sidebar">
      <div class="explenation">
        <template v-if="shownDetails == null">
          <h4 class="title">Explenations</h4>
          <p class="description">
            Hover an item to read more about the individual item
          </p>
        </template>
        <template v-else>
          <h4 class="title">{{ shownDetails.title }}</h4>
          <p class="description">
            {{ shownDetails.description }}
          </p>
        </template>
      </div>
      <div class="buttons">
        <button @click="deleteCurrentCard()">Delete Card</button>
        <button @click="finishCurrentCard()">Finish Card</button>
      </div>
    </div>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import { db } from "@/main";

export default {
  name: "BingoCard",
  props: ["data"],
  data() {
    return {
      shownDetails: null,
    };
  },
  watch: {
    userGotBingo: function (newVal) {
      if (newVal) {
        this.$confetti.start();
        setTimeout(() => {
          this.$confetti.stop();
        }, 15000);
      }
    },
  },
  methods: {
    async deleteCurrentCard() {
      let deletecard = await db
        .collection("bingoCards")
        .doc(this.data.id)
        .delete();
      this.$store.dispatch("fetchBingoCards");
    },
    async toggleItemStatus(item) {
      if (item.title !== "Free") {
        item.checked = !item.checked;
        let updatedCard = await db
          .collection("bingoCards")
          .doc(this.data.id)
          .set(this.data.card);
      }
    },
    async finishCurrentCard() {
      let update = await db
        .collection("bingoCards")
        .doc(this.data.id)
        .update({ expired: true });
      this.$store.dispatch("fetchBingoCards");
    },
  },
  computed: {
    userGotBingo() {
      // Horizontal Checks
      if (
        this.bingoValues[0].checked &&
        this.bingoValues[1].checked &&
        this.bingoValues[2].checked &&
        this.bingoValues[3].checked &&
        this.bingoValues[4].checked
      )
        return true;
      if (
        this.bingoValues[5].checked &&
        this.bingoValues[6].checked &&
        this.bingoValues[7].checked &&
        this.bingoValues[8].checked &&
        this.bingoValues[9].checked
      )
        return true;
      if (
        this.bingoValues[10].checked &&
        this.bingoValues[11].checked &&
        this.bingoValues[13].checked &&
        this.bingoValues[14].checked
      )
        return true;
      if (
        this.bingoValues[15].checked &&
        this.bingoValues[16].checked &&
        this.bingoValues[17].checked &&
        this.bingoValues[18].checked &&
        this.bingoValues[19].checked
      )
        return true;
      if (
        this.bingoValues[20].checked &&
        this.bingoValues[21].checked &&
        this.bingoValues[22].checked &&
        this.bingoValues[23].checked &&
        this.bingoValues[24].checked
      )
        return true;

      //Vertical Bingo

      if (
        this.bingoValues[0].checked &&
        this.bingoValues[5].checked &&
        this.bingoValues[10].checked &&
        this.bingoValues[15].checked &&
        this.bingoValues[20].checked
      )
        return true;
      if (
        this.bingoValues[1].checked &&
        this.bingoValues[6].checked &&
        this.bingoValues[11].checked &&
        this.bingoValues[16].checked &&
        this.bingoValues[21].checked
      )
        return true;
      if (
        this.bingoValues[2].checked &&
        this.bingoValues[7].checked &&
        this.bingoValues[17].checked &&
        this.bingoValues[22].checked
      )
        return true;
      if (
        this.bingoValues[3].checked &&
        this.bingoValues[8].checked &&
        this.bingoValues[13].checked &&
        this.bingoValues[18].checked &&
        this.bingoValues[23].checked
      )
        return true;
      if (
        this.bingoValues[4].checked &&
        this.bingoValues[9].checked &&
        this.bingoValues[14].checked &&
        this.bingoValues[19].checked &&
        this.bingoValues[24].checked
      )
        return true;

      //diagonal bingo

      if (
        this.bingoValues[0].checked &&
        this.bingoValues[6].checked &&
        this.bingoValues[18].checked &&
        this.bingoValues[24].checked
      )
        return true;
      if (
        this.bingoValues[4].checked &&
        this.bingoValues[8].checked &&
        this.bingoValues[16].checked &&
        this.bingoValues[20].checked
      )
        return true;
      return false;
    },
    bingoValues() {
      return this.data.card.values;
    },
    usersBingoCard() {
      return this.$store.getters.usersBingoCard;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
h1 {
  font-weight: 700;
  color: #379683;
  padding-bottom: 10px;
  border-bottom: 4px dotted white;
}
.buttons {
  justify-content: space-around;
  width: 100%;
  display: flex;

  button {
    cursor: pointer;
    background: none;
    border: none;
    padding-bottom: 6px;
    font-size: 24px;
    text-transform: uppercase;
    border-bottom: 1px solid #deb992;
    color: #deb992;
  }
}
.bingo-card {
  display: flex;
  justify-content: center;
  flex-direction: column;
  overflow: scroll;
}
.sidebar {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .explenation {
    display: none;
    margin-top: 32px;
    min-width: 500px;
    padding: 32px 50px;
    box-sizing: border-box;
    border-radius: 10px;
    border: 2px solid white;
    box-shadow: 3px 6px 1px white;
  }

  h4 {
    font-size: 32px;
    margin: 0 0 24px 0;
  }
  p {
    font-size: 20px;
  }
  ul {
    list-style: disc;
    padding: 0;
    li {
      text-align: left;
      padding: 3px 0;

      &.checked {
        text-decoration: line-through;
      }
    }
  }
}
.buttons {
  display: flex;
  justify-content: space-between;
  padding-bottom: 10px;
  margin-bottom: 30px;
  button {
    width: 45%;
    margin-top: 30px;
    background: #379683;
    border: 2px solid #edf5e1;
    padding: 10px 10px;
    border-radius: 10px;
    color: #edf5e1;
    font-size: 14px;
    font-weight: 700;
    box-shadow: 3px 6px 1px white;
    transition: all ease 0.3s;

    &:hover {
      background: #05386b;
    }
  }
}

.grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  overflow: auto;
  min-width: 500px;
  padding: 0 5px 5px 0;

  .item {
    text-align: center;
    width: 18%;
    border: 2px solid white;
    box-shadow: 3px 6px 1px white, inset 4px 3px 3px #8ee4af;
    height: 80px;
    font-size: 11px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    margin-top: 30px;
    user-select: none;
    cursor: pointer;
    transition: all ease 0.3s;
    overflow-wrap: break-word;
    padding: 4px;
    box-sizing: border-box;
    word-break: normal;
    color: #05386b;
    &:hover {
      background: #8ee4af;
      box-shadow: 3px 6px 1px white, inset 4px 3px 3px white;
    }
    &.checked {
      box-shadow: 3px 6px 1px darken($color: #05386b, $amount: 5),
        inset 4px 3px 3px lighten($color: #05386b, $amount: 10);
      background: #05386b;
      border: 2px solid darken($color: #05386b, $amount: 5);
      color: white;
      font-weight: 400;
    }
  }
}
@media (orientation: landscape) {
  .grid {
    .item {
      font-size: 14px;
      word-break: normal;
      height: 80px;
    }
  }
}
@media (min-width: 992px) {
  .bingo-card {
    overflow: unset;
  }
  .header {
    width: 100%;
    margin-bottom: 48px;
  }
  h1 {
    display: inline;
    margin: 24px 0 64px 0;
    font-size: 64px;
    color: #edf5e1;
    font-weight: 700;
    text-align: center;
    -webkit-text-stroke: 3px #05386b; /* width and color */
    text-shadow: 4px 6px 1px #05386b;
  }
  .bingo-card {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
  }

  .sidebar {
    width: 30%;
    padding-left: 10%;
    .explenation {
      display: block;
      height: 100%;
      margin-top: 30px;
    }
    .buttons {
      margin-top: auto;
      margin-bottom: 0;
    }
  }
  .main {
    width: 60%;
    display: flex;
    flex-direction: row;
  }
  .grid {
    .item {
      font-size: 16px;
      height: 100px;
      padding: 10px;
    }
  }
}
</style>
