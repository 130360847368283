<template>
  <div class="play-container" v-if="loaded">
    <BingoCard
      :data="bingoCard"
      v-for="(bingoCard, index) in usersActiveBingoCards"
      :key="index"
    />
    <form
      @submit.prevent="createBingoCard"
      class="get-new-card"
      v-if="usersActiveBingoCards.length == 0"
    >
      <h2>Bingo</h2>
      <select v-model="competitionName" name="Competition Name">
        <option value="" disabled selected>Select tournament</option>
        <option value="Vintage Open">Vintage Open</option>
        <option value="Jonesboro Open">Jonesboro Open</option>
        <option value="Joe Machens Toyota’s Mid-America Open">
          Joe Machens Toyota’s Mid-America Open
        </option>
        <option value="Dynamic Discs Open">Dynamic Discs Open</option>
        <option value="The Challenge at Goat Hill Park">
          The Challenge at Goat Hill Park
        </option>
        <option value="OTB Open">OTB Open</option>
        <option value="United States Women’s Disc Golf Championships">
          United States Women’s Disc Golf Championships
        </option>
        <option value="Santa Cruz Masters Cup">Santa Cruz Masters Cup</option>
        <option value="Portland Open">Portland Open</option>
        <option value="Professional Disc Golf World Championships">
          Professional Disc Golf World Championships
        </option>
        <option value="PCS Sula Open">PCS Sula Open</option>
        <option value="Discraft's Great Lakes Open">
          Discraft's Great Lakes Open
        </option>
        <option value="The Preserve Championship">
          The Preserve Championship
        </option>
        <option value="Discraft's Ledgestone Insurance Open">
          Discraft's Ledgestone Insurance Open
        </option>
        <option value="Idlewild Open">Idlewild Open</option>
        <option value="Stafford Open">Stafford Open</option>
        <option value="Delaware Disc Golf Challenge">
          Delaware Disc Golf Challenge
        </option>
        <option value="MVP Open at Maple Hill">MVP Open at Maple Hill</option>
        <option value="Discraft's Green Mountain Championship">
          Discraft's Green Mountain Championship
        </option>
        <option value="Disc Golf Pro Tour Match Play Championship">
          Disc Golf Pro Tour Match Play Championship
        </option>
        <option value="Music City Open">Music City Open</option>
        <option value="Disc Golf Pro Tour Championship">
          Disc Golf Pro Tour Championship
        </option>
      </select>
      <button type="submit">Create Bingo Card</button>
    </form>
  </div>
</template>

<script>
import firebase from "firebase/app";
import "firebase/auth";
import { db } from "@/main";
import BingoCard from "@/components/BingoCard.vue";

export default {
  name: "Play",
  data() {
    return {
      competitionName: "",
    };
  },
  components: { BingoCard },
  methods: {
    shuffle(array) {
      var currentIndex = array.length,
        temporaryValue,
        randomIndex;

      // While there remain elements to shuffle...
      while (0 !== currentIndex) {
        // Pick a remaining element...
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex -= 1;

        // And swap it with the current element.
        temporaryValue = array[currentIndex];
        array[currentIndex] = array[randomIndex];
        array[randomIndex] = temporaryValue;
      }

      return array;
    },
    async createBingoCard() {
      if (this.competitionName.length > 3) {
        let bingoValues = [];
        let bingoCard = {
          values: [],
          name: this.competitionName,
          owner: firebase.auth().currentUser.uid,
          created: new Date(),
          bingo: false,
          expired: false,
        };

        let values = await db
          .collection("bingoValues")
          .where("active", "==", true)
          .get();

        values.forEach((doc) => {
          bingoValues.push(doc.data());
        });

        const shuffeledValues = JSON.parse(
          JSON.stringify(this.shuffle(bingoValues))
        );

        shuffeledValues.forEach((value, index) => {
          if (index < 24) {
            bingoCard.values.push({
              title: value.title,
              description: value.description,
              checked: false,
            });

            if (index == 11) {
              bingoCard.values.push({
                title: "Free",
                description:
                  "This is a free spot, basically it is already checked for you!",
                checked: true,
              });
            }
          }
        });

        let saveBingoCard = await db
          .collection("bingoCards")
          .doc()
          .set(bingoCard);
        this.$store.dispatch("fetchBingoCards");
      }
    },
  },
  computed: {
    loaded() {
      return this.$store.getters.bingoCardsLoaded;
    },
    usersActiveBingoCards() {
      return this.$store.getters.usersActiveBingoCards;
    },
    userHasBingoCard() {
      return this.$store.getters.usersHasBingoCard;
    },
    userGotBingo() {
      return this.$store.getters.usersBingoCard?.bingo;
    },
  },
  async beforeMount() {
    await this.$store.dispatch("fetchBingoCards");
  },
  mounted() {},
};
</script>
<style lang="scss">
.header {
  justify-content: center;
  display: flex;
  h1 {
    margin: 0;
  }
}
.play-container {
  display: flex;
  justify-content: center;
  margin-top: 48px;
}
form {
  padding: 30px 40px;
  border-radius: 30px;
  width: 325px;
  border: 1px solid #edf5e1;
  box-shadow: 5px 10px 1px #edf5e1;

  select {
    padding: 15px 10px;
    border-radius: 6px;
    margin-bottom: 24px;
    background: none;
    border: 1px solid #edf5e1;
    box-shadow: 5px 4px 1px #379683;
    color: #05386b;
    font-weight: 700;
    font-size: 20px;
    outline: none;
    box-sizing: border-box;
    width: 100%;
    &::placeholder {
      color: #edf5e1;
    }
  }
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    box-shadow: 0 0 0 30px #379683 inset !important;
  }

  button {
    margin-top: 30px;
    width: auto;
    background: #379683;
    border: 1px solid #edf5e1;
    padding: 15px 10px;
    width: 100%;
    border-radius: 10px;
    color: #edf5e1;
    font-size: 20px;
    font-weight: 700;
  }
  h2 {
    font-size: 48px;
    margin: 0px 0 48px 0;
    color: #edf5e1;
    text-shadow: 3px 4px 3px #379683;
  }
}
</style>
