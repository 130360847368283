<template>
  <div class="login-container">
    <form @submit.prevent="login">
      <h2>Login</h2>
      <input type="email" placeholder="Email address..." v-model="email" />
      <input type="password" placeholder="password..." v-model="password" />
      <button type="submit">Login</button>
    </form>
  </div>
</template>

<script>
import firebase from "firebase/app";
import "firebase/auth";
export default {
  name: "Login",
  data() {
    return {
      email: "",
      password: "",
    };
  },
  methods: {
    login() {
      firebase
        .auth()
        .signInWithEmailAndPassword(this.email, this.password)
        .then(() => {
          this.$router.push({ name: "Play" });
        })
        .catch((error) => {
          alert(error.message);
        });
    },
  },
  computed: {},
  mounted() {},
};
</script>
<style lang="scss" scoped>
.login-container {
  display: flex;
  justify-content: center;
  margin-top: 48px;
}
form {
  padding: 30px 40px;
  border-radius: 30px;
  width: 325px;
  border: 1px solid #edf5e1;
  box-shadow: 5px 10px 1px #edf5e1;

  input {
    padding: 15px 10px;
    border-radius: 6px;
    margin-bottom: 24px;
    background: none;
    border: 1px solid #edf5e1;
    box-shadow: 5px 4px 1px #379683;
    color: #05386b;
    font-weight: 700;
    font-size: 20px;
    outline: none;
    box-sizing: border-box;
    width: 100%;
    &::placeholder {
      color: #edf5e1;
    }
  }
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px #379683 inset !important;
  }

  button {
    margin-top: 30px;
    width: auto;
    background: #379683;
    border: 1px solid #edf5e1;
    padding: 15px 10px;
    width: 100%;
    border-radius: 10px;
    color: #edf5e1;
    font-size: 20px;
    font-weight: 700;
  }
  h2 {
    font-size: 48px;
    margin: 0px 0 48px 0;
    color: #edf5e1;
    text-shadow: 3px 4px 3px #379683;
  }
}
</style>
